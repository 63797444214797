////////////////////////////////////////////////////////////
//     							                                      //
//  Program: App.jsx                                      //
//  Application: Components                               //
//  Option: App component                                 //
//  Developer: CV  						                            //
//  Date: 2021-03-01                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import "../assets/scss/calendar.scss";
import AppRoutes from "./AppRoutes";
import Header from "./common/Header";
import HeaderTwo from "./common/HeaderTwo";
import HeaderTop from "./common/HeaderTop";
import HeaderTopTwo from "./common/HeaderTopTwo";
// import Footer from './common/Footer'
import OnKeyPressEvent from "./common/OnKeyPressEvent";
import CommonMessages from "./common/CommonMessages";
import Moment from "moment";
import defaultImage from "../assets/images/profile-nogender.png";
import propertyLogo from "../assets/images/property_placeholder.png";
import mobmenu from "../assets/images/mobmenu.svg";
import "react-phone-input-2/lib/style.css";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import addNotification from "react-push-notification";
import moment from "moment-timezone";
// import renderHTML from "react-render-html";
import ohp from "../assets/images/logo_2.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BuildingOnboardingService from "../services/BuildingOnboardingService";

// Set data as a global for common use
global.spinnerLoader = (
  <div className="spinnerLoader">
    <div className="toploader d-none">
      <img src={require("../assets/images/Loader.gif").default} alt="" />
    </div>
  </div>
);

// loader for snapshot
global.snapshotloader = <Skeleton count={10} height={40} />;
// loader for snapshot
global.keydataloader = <Skeleton height="100vh" width="100vw" />;

global.closee = (
  <img src={require("../assets/images/close-wel.svg").default} alt="" />
);
global.loader = (
  <span>
    <span
      className="spinner-border spinner-border-sm mg-r-5"
      role="status"
      aria-hidden="true"
    ></span>
    Loading...
  </span>
);
// Use for different background colors in charts
global.chartBgColors = [
  "#560bd0",
  "#007bff",
  "#00cccc",
  "#cbe0e3",
  "#74de00",
  "#0074D9",
  "#FF4136",
  "#2ECC40",
  "#FF851B",
  "#7FDBFF",
  "#B10DC9",
  "#FFDC00",
  "#001f3f",
  "#39CCCC",
  "#01FF70",
  "#85144b",
  "#F012BE",
  "#3D9970",
  "#111111",
  "#AAAAAA",
  "#2ecc71",
  "#3498db",
  "#95a5a6",
  "#9b59b6",
  "#f1c40f",
  "#e74c3c",
  "#34495e",
  "#25CCF7",
  "#FD7272",
  "#54a0ff",
  "#00d2d3",
  "#1abc9c",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#e67e22",
  "#ecf0f1",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
  "#55efc4",
  "#81ecec",
  "#74b9ff",
  "#a29bfe",
  "#dfe6e9",
  "#00b894",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
  "#ffeaa7",
  "#fab1a0",
  "#ff7675",
  "#fd79a8",
  "#fdcb6e",
  "#e17055",
  "#d63031",
  "#feca57",
  "#5f27cd",
  "#01a3a4",
];
// To check user's access permissions
global.userPermissions = new OnKeyPressEvent();
// Use for datatable
global.page_length_menu = [30, 50, 100];
global.userType = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData")).user_type
  : 5; //get user role or else consider guest login
global.page_size = process.env.REACT_APP_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_PAGE_SIZE)
  : 30;
global.show_filter =
  process.env.REACT_APP_SHOW_FILTER === "true" ? true : false;
global.show_pagination_info =
  process.env.REACT_APP_SHOW_PAGINATION_INFO === "true" ? true : false;
global.show_pagination =
  process.env.REACT_APP_SHOW_PAGINATION === "true" ? true : false;
global.pagination = process.env.REACT_APP_PAGINATION
  ? process.env.REACT_APP_PAGINATION
  : "basic";
global.excel_button =
  process.env.REACT_APP_SHOW_EXCEL_BUTTON === "true" ? true : false;
global.print_button =
  process.env.REACT_APP_SHOW_PRINT_BUTTON === "true" ? true : false;
global.filter_time = process.env.REACT_APP_FILTER_TIME
  ? parseInt(process.env.REACT_APP_FILTER_TIME)
  : 0;
// User for datatble to display all records without pagination
global.all_page_length_menu = [];
global.max_page_size = process.env.REACT_APP_MAX_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_MAX_PAGE_SIZE)
  : 1000000;
global.hide_filter =
  process.env.REACT_APP_HIDE_FILTER === "true" ? true : false;
global.hide_pagination_info =
  process.env.REACT_APP_HIDE_PAGINATION_INFO === "true" ? true : false;
global.hide_pagination =
  process.env.REACT_APP_HIDE_PAGINATION === "true" ? true : false;
// Use for countdown timer
global.countdown_time = process.env.REACT_APP_TIMER_VALUE
  ? parseFloat(process.env.REACT_APP_TIMER_VALUE)
  : 0;
// Use for page redirection after save/update data
global.redirect_time = process.env.REACT_APP_AUTO_REDIRECT_TIME
  ? parseFloat(process.env.REACT_APP_AUTO_REDIRECT_TIME)
  : 0;
// Use for auto alert popup close time after display
global.alert_close_time = process.env.REACT_APP_ALERT_CLOSE_TIME
  ? parseFloat(process.env.REACT_APP_ALERT_CLOSE_TIME)
  : 0;
// Use for authentication
global.headerToken = localStorage.getItem("headerToken")
  ? localStorage.getItem("headerToken")
  : "";
global.userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : {};

// Use for state list
global.stateList = localStorage.getItem("stateList")
  ? JSON.parse(localStorage.getItem("stateList"))
  : [];
// Use for country list
global.countryList = localStorage.getItem("countryList")
  ? JSON.parse(localStorage.getItem("countryList"))
  : [];
// Data from setting apis
global.currentPeriod = localStorage.getItem("currentPeriod")
  ? localStorage.getItem("currentPeriod")
  : "";
global.finYearEnd = localStorage.getItem("finYearEnd")
  ? localStorage.getItem("finYearEnd")
  : "";
global.lastUpdate = localStorage.getItem("lastUpdate")
  ? localStorage.getItem("lastUpdate")
  : "";
global.prevPeriodDate = localStorage.getItem("prevPeriodDate")
  ? localStorage.getItem("prevPeriodDate")
  : "";
global.currentPeriodDate = localStorage.getItem("currentPeriodDate")
  ? localStorage.getItem("currentPeriodDate")
  : "";
global.permissions = localStorage.getItem("permissions")
  ? localStorage.getItem("permissions")
  : "";
global.currerntRoleGroup = localStorage.getItem("currerntRoleGroup")
  ? localStorage.getItem("currerntRoleGroup")
  : "";
global.currerntRoleSlug = localStorage.getItem("currerntRoleSlug")
  ? localStorage.getItem("currerntRoleSlug")
  : "";
// global.roleList = (localStorage.getItem('roleList')) ? JSON.parse(localStorage.getItem('roleList')) : {};
global.propertSlug = localStorage.getItem("propertyList")
  ? localStorage.getItem("propertyList")[0].slug
  : "";
global.propertList = localStorage.getItem("propertyList")
  ? JSON.parse(localStorage.getItem("propertyList"))
  : [];

global.yearDropdownItemNumber = 3;
global.successStatus = [200, 201, 202];
global.showOpen = false;
global.isSearch = false;
global.isModel = false;
global.textareaRowLength = "10";
global.showMoreLength = "750";
global.dateFormat = "MM/DD/YY";
global.timeFormat = "hh:mm A";
// global.dateTimeFormat = "MM/DD/YY | hh:mm A";
global.dateTimeFormat = "MM/DD/YY[\r\n]hh:mm A";

global.current_year = Moment(new Date()).format("YYYY");
global.country = "us";
// Use for prevent values on keypress
global.onKeyPressEvent = new OnKeyPressEvent();
global.commonMessages = new CommonMessages();
//day list
global.dayList = [
  { key: "1", value: "1", label: "Monday" },
  { key: "2", value: "2", label: "Tuesday" },
  { key: "3", value: "3", label: "Wednesday" },
  { key: "4", value: "4", label: "Thursday" },
  { key: "5", value: "5", label: "Friday" },
  { key: "6", value: "6", label: "Saturday" },
  { key: "7", value: "7", label: "Sunday" },
];
global.currencyCode = [
  { key: "1", value: "USD", label: "USD" },
  { key: "2", value: "EUR", label: "EUR" },
  { key: "3", value: "CAD", label: "CAD" },
];
global.pricingType = [
  { key: "1", value: "1", label: "Flat" },
  { key: "2", value: "2", label: "Weekly" },
  { key: "3", value: "3", label: "Monthly" },
];
global.fontFamily = [
  {
    label: "Canada Type-OrpheusPro-Bold",
    value: "Canada Type-OrpheusPro-Bold",
  },
  {
    label: "TAN-PEARL",
    value: "TAN-PEARL",
  },
  {
    label: "OrpheusPro-Bold",
    value: "OrpheusPro-Bold",
  },
  {
    label: "Lato-Light",
    value: "Lato-Light",
  },
  {
    label: "Lato-Regular",
    value: "Lato-Regular",
  },
];
global.fontSize = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 14,
    value: 14,
  },
  {
    label: 16,
    value: 16,
  },
  {
    label: 18,
    value: 18,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 22,
    value: 22,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 26,
    value: 26,
  },
  {
    label: 28,
    value: 28,
  },
  {
    label: 30,
    value: 30,
  },
];
global.brandColList = [
  { label: "Brand Name", value: "brand_name" },
  { label: "Brand SKU", value: "aka_name" },
  { label: "Contact Name", value: "contact_name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone_number" },
];
global.propertyColList = [
  { label: "Property Name", value: "property_name" },
  { label: "Property SKU", value: "aka_name" },
  { label: "Brand", value: "brand_name" },
  { label: "Email", value: "property_email" },
  { label: "Phone Number", value: "property_phone_number" },
];
global.floorplanColList = [
  { label: "Floor Plan Name", value: "property_floor_plan_name" },
  // {label:"Flooring",value:"flooring"},
  // {label:"Heating",value:"heating"},
  // {label:"Square Foot",value:"square_foot"},
  { label: "Bedrooms", value: "bedroom" },
  { label: "Bathrooms", value: "bath" },
  // {label:"Balcony (Sq. ft)",value:"balcony"},
  // {label:"Interior (Sq. ft)",value:"interior"}
];
global.unitColList = [
  { label: "Unit Name", value: "unit_number" },
  { label: "Unit SKU", value: "unit_code" },
  // {label:"Rent",value:"rent"},
  // {label:"Deposit",value:"deposit"},
  // {label:"Floor Plan",value:"floor_plan_name"},
  // {label:"Floor",value:"floor_name"},
  // {label:"Tower",value:"tower_name"},
  { label: "Available From", value: "available_from" },
];
global.eventColList = [{ label: "Event Name", value: "event_name" }];
global.listingColList = [
  { label: "Unit Name", value: "unit_name" },
  { label: "Listing Name", value: "listing_name" },
  // {label:"Type",value:"renting_type_name"},
  { label: "Resident", value: "resident_name" },
  { label: "From Date", value: "from_date" },
  { label: "To Date", value: "to_date" },
  { label: "Bedrooms", value: "bedroom" },
  { label: "Bathrooms", value: "bath" },
  // {label:"ADR",value:"nightly_rate"},
  // {label:"Total Amount",value:"total_amount"},
];
global.leasingColList = [
  { label: "Unit Name", value: "unit_name" },
  { label: "Type", value: "unit_type_name" },
  { label: "Resident", value: "resident_name" },
  { label: "Lease Start Date", value: "lease_start_date" },
  { label: "Lease End Date", value: "lease_end_date" },
  { label: "Lease Signed Date", value: "lease_signed_date" },
];
global.reservationColList = [
  { label: "Unit", value: "unit_name" },
  { label: "Listing Name", value: "listing_name" },
  { label: "Guest", value: "guest_name" },
  { label: "Confirmation Code", value: "confirmation_code" },
  { label: "Check-In Date From", value: "checkin_date" },
  { label: "Check-In Date To", value: "checkin_date_to" },
  { label: "Check-Out Date From", value: "checkout_date" },
  { label: "Check-Out Date To", value: "checkout_date_to" },
  { label: "Booked Date From", value: "booked_date" },
  { label: "Booked Date To", value: "booked_date_to" },
];
global.towerColList = [{ label: "Tower Name", value: "tower_name" }];
global.floorColList = [{ label: "Floor Name", value: "floor_name" }];
global.applicantColList = [
  { label: "Applicant Name", value: "applicant_name" },
];
global.guestColList = [
  { label: "Guest Name", value: "guest_name" },
  { label: "Email", value: "email" },
  { label: "Phone Number", value: "phone_number" },
  { label: "City", value: "city_name" },
  { label: "State", value: "state_name" },
  { label: "Country", value: "country_name" },
  { label: "Zipcode", value: "zip_code" },
];
global.residentColList = [
  { label: "Unit", value: "unit_name" },
  { label: "Resident Name", value: "resident_name" },
  { label: "Email", value: "email" },
  { label: "Phone Number", value: "phone_number" },
  { label: "City", value: "city_name" },
  { label: "State", value: "state_name" },
  { label: "Country", value: "country_name" },
  { label: "Zipcode", value: "zip_code" },
];
global.userColList = [{ label: "Name", value: "first_name" }];
global.commonColList = [{ label: "Name", value: "name" }];

global.RoleListForQuickReply = [
  { label: "Guest", value: 5 },
  { label: "Staff", value: 3 },
  { label: "Resident", value: 4 },
];
global.RoleGroup = {
  Guest: 5,
  Staff: 3,
  Resident: 4,
  Admin: 1,
  Concierge: "Concierge",
};

global.RoleGroupName = {
  Guest: "Guest",
  Staff: "Staff",
  Resident: "Resident",
  Admin: "Admin",
  Concierge: "Concierge",
};
global.is_support = 1;

global.threadType = { Guest: 1, Staff: 2, Resident: 3 };
global.setChannel = { Pms: 1, Airbnb: 2, Website: 3, expedia: 4, vrbo: 5, bookingcom: 6 };
global.setChannelName = {
  starbox: "Starbox",
  Airbnb: "Airbnb",
  Website: "Mobile",
};
global.statuslist = [
  { label: "Open", value: 1 },
  { label: "Converted", value: 2 },
  { label: "Lost", value: 3 },
  { label: "Application Started", value: 4 },
  { label: "Application Not Started", value: 5 },
  { label: "Application Submitted", value: 6 },
  { label: "Application Not Submitted", value: 7 },
  { label: "Application Approved", value: 8 },
  { label: "Application Not Approved", value: 9 },
  { label: "Lease Created", value: 10 },
  { label: "Lease Not Created", value: 11 },
  { label: "Lease Signed", value: 12 },
  { label: "Lease Not Signed", value: 13 },
];

global.lockTypeList = [
  { label: "KeyCafe", value: 1 },
  { label: "Latch", value: 2 },
  { label: "Other", value: 10 }
];

global.modulelist = [
  { label: "Reservation", value: 1 },
  { label: "Listing", value: 2 },
  { label: "Leasing", value: 3 },
  { label: "Resident", value: 4 },
  { label: "Sign In", value: 5 },
];

global.moduleReferlist = [
  { label: "first_name", value: 1, moduleid: 1, slug: "ACE064GTL2SQ" },
  { label: "first_name", value: 1, moduleid: 1, slug: "9MRID43QK1AZ" },
  { label: "first_name", value: 1, moduleid: 1, slug: "D4NEQ76XYB18" },
  { label: "first_name", value: 1, moduleid: 1, slug: "UAWDJMIRZQ9L" },
  { label: "verification_code", value: 2, moduleid: 1, slug: "I6D09C7FV8MZ" },
  { label: "reservation_download_link", value: 3, moduleid: 1, slug: "" },
  { label: "set_password_link", value: 4, moduleid: 1, slug: "ACE064GTL2SQ" },
  { label: "set_password_link", value: 4, moduleid: 1, slug: "UAWDJMIRZQ9L" },
  { label: "user_name", value: 25, moduleid: 1, slug: "XF93LYDZ4TJV" },
  { label: "user_email", value: 26, moduleid: 1, slug: "XF93LYDZ4TJV" },
  { label: "set_password_link", value: 27, moduleid: 1, slug: "XF93LYDZ4TJV" },
  { label: "property_name", value: 30, moduleid: 1, slug: "XF93LYDZ4TJV" },
  { label: "user_name", value: 31, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "user_phone", value: 28, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "notes", value: 29, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "property_name", value: 31, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "confirmation_code", value: 32, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "lock_type", value: 33, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "unit_name", value: 34, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "is_form_submitted", value: 35, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "checkin_date", value: 36, moduleid: 1, slug: "891KTI7CPJ4R" },
  { label: "checkout_date", value: 37, moduleid: 1, slug: "891KTI7CPJ4R" },
  {
    label: "reset_password_link",
    value: 19,
    moduleid: 1,
    slug: "9MRID43QK1AZ",
  },
  { label: "user_name", value: 18, moduleid: 1, slug: "OW1UFCRAHLXQ" },
  { label: "user_email", value: 14, moduleid: 1, slug: "OW1UFCRAHLXQ" },
  { label: "user_name", value: 18, moduleid: 1, slug: "T8Y9PJ1MH5BN" },
  { label: "user_email", value: 14, moduleid: 1, slug: "T8Y9PJ1MH5BN" },
  // { label: "user_phone", value: 14, moduleid: 1, slug: "T8Y9PJ1MH5BN" },
  { label: "description", value: 14, moduleid: 1, slug: "T8Y9PJ1MH5BN" },
  { label: "page_from", value: 14, moduleid: 1, slug: "T8Y9PJ1MH5BN" },
  {
    label: "person_phone_number",
    value: 15,
    moduleid: 1,
    slug: "YK076NJDPBFU",
  },
  { label: "person_name", value: 18, moduleid: 1, slug: "YK076NJDPBFU" },
  { label: "person_email", value: 14, moduleid: 1, slug: "YK076NJDPBFU" },
  { label: "reception_receipt", value: 16, moduleid: 1, slug: "" },
  { label: "description", value: 17, moduleid: 1, slug: "DV2INBT8GZPU" },
  { label: "description", value: 17, moduleid: 1, slug: "EO9P4I63DBT8" },
  { label: "apple_icon", value: 20, moduleid: 1, slug: "D4NEQ76XYB18" },
  { label: "android_icon", value: 21, moduleid: 1, slug: "D4NEQ76XYB18" },
  { label: "footer", value: 22, moduleid: 1, slug: "" },
  { label: "property_name", value: 22, moduleid: 1, slug: "YK076NJDPBFU" },
  { label: "redirection_url", value: 23, moduleid: 1, slug: "YK076NJDPBFU" },
  { label: "property_email", value: 34, moduleid: 1, slug: "YK076NJDPBFU" },
  {
    label: "property_phone_number",
    value: 41,
    moduleid: 1,
    slug: "YK076NJDPBFU",
  },
  { label: "page_from", value: 24, moduleid: 1, slug: "OW1UFCRAHLXQ" },
  {
    label: "export_hostgpo_file_url",
    value: 35,
    moduleid: 1,
    slug: "VEFXDA0CO79Z",
  },
  { label: "guest_first_name", value: 36, moduleid: 1, slug: "SACY5MRF7PT4" },
  { label: "check_in_time", value: 37, moduleid: 1, slug: "SACY5MRF7PT4" },
  { label: "check_in_date", value: 38, moduleid: 1, slug: "SACY5MRF7PT4" },
  { label: "property_name", value: 39, moduleid: 1, slug: "SACY5MRF7PT4" },
  { label: "first_name", value: 37, moduleid: 1, slug: "H7LS5ZMQ3269" },
  { label: "user_name", value: 38, moduleid: 1, slug: "H7LS5ZMQ3269" },
  { label: "user_email", value: 39, moduleid: 1, slug: "H7LS5ZMQ3269" },
  { label: "first_name", value: 37, moduleid: 1, slug: "YGE69SOCAI3W" },
  { label: "user_name", value: 38, moduleid: 1, slug: "YGE69SOCAI3W" },
  { label: "user_email", value: 39, moduleid: 1, slug: "YGE69SOCAI3W" },
  { label: "set_password_link", value: 39, moduleid: 1, slug: "YGE69SOCAI3W" },
  { label: "first_name", value: 41, moduleid: 1, slug: "WA17JG9TV8LZ" },
  { label: "onboarding_url", value: 42, moduleid: 1, slug: "WA17JG9TV8LZ" },
  { label: "user_email", value: 43, moduleid: 1, slug: "WA17JG9TV8LZ" },
  {
    label: "weekly_api_call_count",
    value: 40,
    moduleid: 1,
    slug: "3LM6RZH50YIN",
  },
];

global.triggerPoint = [
  { key: 2, value: 2, label: "Check In" },
  { key: 3, value: 3, label: "Check Out" },
  { key: 1, value: 1, label: "Booking Confirmed" },
  { key: 39, value: 39, label: "Booking Cancelled" },
  { key: 38, value: 38, label: "Booking Date Changed" },
  { key: 4, value: 4, label: "Daily" },
  { key: 5, value: 5, label: "Newly Added Leases" },
  { key: 6, value: 6, label: "Leases Expiring" },
  { key: 7, value: 7, label: "Lease Inquiry" },
  { key: 8, value: 8, label: "Listing Published" },
  { key: 9, value: 9, label: "Early Cleaning Notification" },
  { key: 11, value: 11, label: "Stripe Linked" },
  { key: 12, value: 12, label: "Listing Info Updated" },
  { key: 13, value: 13, label: "Turno Linked" },
  { key: 14, value: 14, label: "Listing Submitted" },
  { key: 15, value: 15, label: "Payout" },
  { key: 66, value: 66, label: "Payout Transfer" },
  { key: 16, value: 16, label: "Guest Form Reminder" },
  { key: 34, value: 34, label: "Guest Form Submitted" },
  { key: 37, value: 37, label: "Resident Stay Confirmed" },
  { key: 17, value: 17, label: "Resident Check In" },
  { key: 18, value: 18, label: "Resident Check Out" },
  { key: 41, value: 41, label: "Resident Stay Delete" },
  { key: 19, value: 19, label: "Cleaning Project Created" },
  { key: 20, value: 20, label: "Cleaning Project Updated" },
  { key: 21, value: 21, label: "Cleaning Project Deleted" },
  { key: 22, value: 22, label: "Cleaning Started" },
  { key: 23, value: 23, label: "Cleaning Ends" },
  { key: 24, value: 24, label: "Cleaning Not Started" },
  { key: 33, value: 33, label: "Cleaning Still Not Started" },
  { key: 25, value: 25, label: "Problem Reported" },
  { key: 26, value: 26, label: "1 Star Review" },
  { key: 27, value: 27, label: "2 Star Review" },
  { key: 28, value: 28, label: "3 Star Review" },
  { key: 29, value: 29, label: "4 Star Review" },
  { key: 30, value: 30, label: "5 Star Review" },
  { key: 31, value: 31, label: "Review Reminder" },
  { key: 32, value: 32, label: "Reservation Inquiry" },
  { key: 36, value: 36, label: "Homesharing Inquiry" },
  { key: 42, value: 42, label: "Homesharing Inquiry Approved Status" },
  { key: 35, value: 35, label: "Access Support Submitted" },
  { key: 40, value: 40, label: "Onboarding Completed Without Bookability" },
  { key: 43, value: 43, label: "Building Onboarding Basics" }, 
  { key: 44, value: 44, label: "Building Onboarding Basics Pending" },
  { key: 45, value: 45, label: "Building Onboarding Media & Files" }, 
  { key: 46, value: 46, label: "Building Onboarding Media & Files Pending" },
  { key: 47, value: 47, label: "Building Onboarding Amenities" }, 
  { key: 48, value: 48, label: "Building Onboarding Amenities Pending" },
  { key: 49, value: 49, label: "Building Onboarding Parking Plan" }, 
  { key: 50, value: 50, label: "Building Onboarding Parking Plan Pending" },
  { key: 51, value: 51, label: "Building Onboarding Banking Info" }, 
  { key: 52, value: 52, label: "Building Onboarding Banking Info Pending" },
  { key: 53, value: 53, label: "Building Onboarding CSV Import" }, 
  { key: 54, value: 54, label: "Building Onboarding CSV Import Pending" },
  { key: 55, value: 55, label: "Building Onboarding Lease Addendum" }, 
  { key: 56, value: 56, label: "Building Onboarding Lease Addendum Pending" },
  { key: 57, value: 57, label: "Building Onboarding Staff Training" }, 
  { key: 58, value: 58, label: "Building Onboarding Staff Training Pending" },
  { key: 59, value: 59, label: "Building Onboarding Announce Homesharing" }, 
  { key: 60, value: 60, label: "Building Onboarding Announce Homesharing Pending" },
  { key: 61, value: 61, label: "Building Onboarding Install Key Locker" }, 
  { key: 62, value: 62, label: "Building Onboarding Install Key Locker Pending" },
  { key: 63, value: 63, label: "Building Onboarding Permit Plan" }, 
  { key: 64, value: 64, label: "Building Onboarding Permit Plan Pending" },
  { key: 65, value: 65, label: "Building Onboarding Completed" },

];

global.bulidingonboarding = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.bulidingonboardingpending = [
  { key: 2, value: 2, label: "After" },
];

global.bookingcancelled = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.residentopendate = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.bookingdatechanged = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.cleaning = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.homesharing = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.residentstayconfirmation = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.cleaningnotstart = [{ key: 2, value: 2, label: "After" }];

global.cleaningstillnotstart = [{ key: 3, value: 3, label: "Before" }];

global.guestform = [{ key: 3, value: 3, label: "Before" }];

global.reviewReminder = [{ key: 2, value: 2, label: "After" }];

global.supportformsubmitted = [
  { key: 1, value: 1, label: "Immediately After" },
];
global.payoutWhenToSend = [{ key: 1, value: 1, label: "Immediately After" }];

global.reservationInquiry = [{ key: 1, value: 1, label: "Immediately After" }];

global.firing = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
  { key: 3, value: 3, label: "Before" },
];
global.firing12 = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];
global.guestform = [{ key: 3, value: 3, label: "Before" }];

global.guestformsubmitted = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
];

global.firingexpringlease = [{ key: 3, value: 3, label: "Before" }];
global.firingleaseinquiry = [
  { key: 1, value: 1, label: "Immediately After" },
  // { key: 2, value: 2, label: 'After' },
];
global.onboarding = [
  { key: 1, value: 1, label: "Immediately After" },
  // { key: 2, value: 2, label: 'After' },
];
global.resident = [
  { key: 1, value: 1, label: "Immediately After" },
  { key: 2, value: 2, label: "After" },
  { key: 3, value: 3, label: "Before" },
];
global.headerlogo = [
  { key: 1, value: 1, label: "Property" },
  { key: 2, value: 2, label: "Orion Haus" },
];

global.whentosend1 = 2;
global.whentosend2 = 3;
global.whentosend3 = 4;

global.modulesubjectVariable = [
  { label: "brand_name", value: 18, moduleid: 1, slug: "YK076NJDPBFU" },
  { label: "property_name", value: 19, moduleid: 1, slug: "YK076NJDPBFU" },
  { label: "property_name", value: 28, moduleid: 1, slug: "XF93LYDZ4TJV" },
];

global.residentTypeList = [
  { label: "All", value: 1 },
  { label: "Current", value: 2 },
  { label: "Future", value: 3 },
  { label: "Past", value: 4 },
];
global.guestTypeList = [
  { label: "All", value: 1 },
  { label: "Current", value: 2 },
  { label: "Future", value: 3 },
  { label: "Past", value: 4 },
];
global.onboardingStepList = [
  { label: "Who linked Stripe", value: 1 },
  { label: "Who fill up listing info", value: 2 },
  { label: "Who linked Turno", value: 3 },
  { label: "Who finished", value: 4 },
  { label: "Who published", value: 5 },
];
global.crmPaiUnpaid = [
  { label: "Paid", value: 1 },
  { label: "Unpaid", value: 2 },
];
global.crmConditions = [
  { label: "AND", value: 1 },
  { label: "OR", value: 2 },
];

global.cmsContentType = [
  { label: "Rich Text Editor", value: 1 },
  { label: "Simple Text", value: 2 },
  { label: "Rich Text with Image", value: 3 },
  { label: "Rich Text with Video Url", value: 4 },
  { label: "Simple Text with Image", value: 5 },
  { label: "Simple Text with Video Url", value: 6 },
];
global.cmsModuleName = [
  { label: "Insights", value: 1 },
  { label: "Miscellaneous", value: 2 },
  { label: "Onboarding", value: 3 },
  { label: "Resources", value: 4 },
  { label: "Latch", value: 5 },
  { label: "Access Support", value: 6 },
  { label: "App V3", value: 7 },
  { label: "Waiver", value: 8 },
  { label: "Building Onboarding", value: 9 },
];
global.crmFooter = [
  { key: 1, value: 1, label: "Show" },
  { key: 2, value: 2, label: "Hide" },
];

global.crmReviews = [
  { label: "Review 1", value: 1 },
  { label: "Review 2", value: 2 },
  { label: "Review 3", value: 3 },
  { label: "Review 4", value: 4 },
  { label: "Review 5", value: 5 },
];
// For by default Show/Hide Custom Filter
global.showFilter = 0;
var websocketCoonectionString = null;
var socketData = [];
var conn = [];
class App extends Component {
  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }
  constructor(props) {
    super(props);

    this.state = {
      profileImage: "",
      isNewSidebar: false,
      isNewTopbar: false,
      property_name: "",
      property_slug: "",
      property_logo: "",
      property_sku: "",
      msgCountGlobal: 0,
      SOCKET_SERVER_URL: process.env.REACT_APP_WEBSOCKET_ENDPOINT
        ? process.env.REACT_APP_WEBSOCKET_ENDPOINT
        : "",
      has_concierge_permission: 0,
      assignPropertyString: [],
      connectionStablish: false,
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.updatePropertyLogo = this.updatePropertyLogo.bind(this);
    this.getPropertyName = this.getPropertyName.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);
    this.getPropertyLogo = this.getPropertyLogo.bind(this);
    this.getPropertySKU = this.getPropertySKU.bind(this);
    this.updatePropsGlobalmsg = this.updatePropsGlobalmsg.bind(this);
    this.getOnboardingSetpsCount = this.getOnboardingSetpsCount.bind(this);
  }

  componentDidMount() {
    this.onRouteChanged();

    /* Today's date find browser wise */
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    this.setState({ today: today });
    today = moment(moment.utc(today).toDate()).local().format("MM/DD/YYYY");
    /* Convert property array list to property string */
    let propertyList = global.propertList;
    let assignPropertyString = null;
    if (propertyList.length > 0) {
      assignPropertyString = propertyList
        .map((item, i) => `${item.slug}`)
        .join(",");
    }
    /* Login user have concierge permission */
    let has_concierge_permission =
      global.userPermissions.checkPermission(
        "communication-message-contact-concierge"
      ) === true
        ? 1
        : 0;
    var header = global.headerToken.split("|").join("_");
    /* Socket connection string */
    websocketCoonectionString =
      this.state.SOCKET_SERVER_URL +
      "?user_slug=" +
      global.userData.slug +
      "&has_concierge_permission=" +
      has_concierge_permission +
      "&user_role_group_id=" +
      global.currerntRoleGroup +
      "&assignd_property=" +
      assignPropertyString +
      "&header_token=" +
      header;
    console.log("connection string", websocketCoonectionString);
    conn = new WebSocket(websocketCoonectionString);
    conn.onopen = function (e) {
      console.log("onopen", e);
      return (e = true);
    };
    /* socket message logic */
    conn.onmessage = (e) => {
      /* For unread thread of tab and top right cornor of message */
      socketData = JSON.parse(e.data);
      console.log("app socket data", socketData);
      let msgCountGlobal = 1;
      this.setState({
        msgCountGlobal: msgCountGlobal,
        socketData: socketData,
        socketThread: socketData.thread_slug,
      });
      if (localStorage.getItem("current_thread") !== socketData.thread_slug) {
        this.pushForWeb(socketData);
      }
    };
    /* Socket close */
    conn.onclose = () => {
      console.log("Connection closed");
      setInterval(function () {
        console.log("try connection string", websocketCoonectionString);
        conn = new WebSocket(websocketCoonectionString);
        conn.onopen = function (e) {
          console.log("onopen after socket disconned", e);
          //this.setState({connectionStablish: false});
          return (e = true);
        };
      }, 1 * 60 * 1000);

      // }
    };
    /* Hand shaking method every 2 min */
    setInterval(function () {
      var loginUserSlug = global.userData.slug + "-" + header;
      conn.send(loginUserSlug);
      console.log(loginUserSlug, "Date Time", new Date().toLocaleString());
    }, 2 * 60 * 1000);

    /* msg tab open or not  */
    setTimeout(() => {
      this.getOnboardingSetpsCount(sessionStorage.getItem("currerntPropSlug"));
    }, 100);
  }

  /* Push Notification for message */
  pushForWeb(socketData) {
    let senderName = socketData.sender_name
      ? socketData.sender_name
      : "OHAI Concierge";
    let propertySlug = socketData.property ? socketData.property.slug : "";
    let message = "";
    if (socketData.message_type === 3) {
      var msg = socketData.preview ? socketData.preview : "Shared a file";
      message =
        msg.length > 20 ? `${msg.substring(0, 20)}...` : msg.substring(0, 20);
    } else {
      message = "Shared a file";
    }
    var threadSlug = socketData.thread_slug ? socketData.thread_slug : "";
    let propertyImage = socketData.property
      ? socketData.property.property_logo_thumb_url
      : ohp;
    addNotification({
      title: senderName,
      subtitle: senderName,
      message: message,
      theme: "darkblue",
      icon: propertyImage,
      duration: 100000,
      native: true, // when using native, your OS will handle theming.
      onClick: () => (
        (window.location =
          "/properties/view/" + propertySlug + "/messages/" + threadSlug),
        "_blank"
      ),
    });
  }
  updatePropsGlobalmsg(msgCount) {
    this.setState({ msgCountGlobal: msgCount });
  }

  updateProfile(imagePath) {
    if (imagePath) {
      this.setState({ profileImage: imagePath });
    } else {
      var img =
        localStorage.getItem("userData") !== null &&
        localStorage.getItem("userData") !== undefined
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage;
      this.setState({ profileImage: img });
    }
  }

  updatePropertyLogo(imagePath) {
    if (imagePath) {
      this.setState({ imgUrl: imagePath });
    } else {
      var img =
        sessionStorage.getItem("property_logo_url") !== null &&
        sessionStorage.getItem("property_logo_url") !== undefined
          ? sessionStorage.getItem("property_logo_url")
          : propertyLogo;
      this.setState({ imgUrl: img });
    }
  }

  getPropertyName(name) {
    if (name) {
      this.setState({ property_name: name });
    } else {
      this.setState({ property_name: "" });
    }
  }

  getPropertySlug(slug) {
    if (slug) {
      this.setState({ property_slug: slug });
    } else {
      this.setState({ property_slug: "" });
    }
  }

  getPropertyLogo(logo) {
    if (logo) {
      this.setState({ property_logo: logo });
    } else {
      this.setState({ property_logo: "" });
    }
  }

  getPropertySKU(sku) {
    if (sku) {
      this.setState({ property_sku: sku });
    } else {
      this.setState({ property_sku: "" });
    }
  }

  async getOnboardingSetpsCount(slug) {
    var totalCount = 0;
    var setpsCount = 0;

    let res = await BuildingOnboardingService.getOnboardingStepsCount(slug);
    if (global.successStatus.includes(res.status)) {
      totalCount = res.data ? res.data.totalCount : 0;
      setpsCount = res.data ? res.data.setpsCount : 0;
    }

    this.setState({
      totalCount: totalCount,
      setpsCount: setpsCount,
    });
  }

  toggleSecondaryMainMenu(e) {
    // e.preventDefault();
    const data = document.querySelector("body");
    if (data != null) {
      if (data.classList.contains("az-header-menu-show")) {
        document.querySelector("body").classList.remove("az-header-menu-show");
      }
    }
  }

  toggleSecondaryMenu(e) {
    // e.preventDefault();
    if (document.querySelector(".newSubmenu")) {
      document.querySelector(".newSubmenu").classList.add("d-none");
    }

    if (document.querySelector(".mobchildMenu")) {
      document.querySelector(".mobchildMenu").classList.add("d-none");
    }
  }

  render() {
    let headerComponent = !this.state.isFullPageLayout ? (
      !this.state.isNewSidebar ? (
        <Header {...this.props} setpsCount={this.state.setpsCount} />
      ) : (
        <HeaderTwo
          {...this.props}
          property_slug={this.state.property_slug}
          propertyLogo={this.state.property_logo}
          propertySKU={this.state.property_sku}
          setpsCount={this.state.setpsCount}
        />
      )
    ) : (
      ""
    );
    // let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
    let HeaderTopComponent = !this.state.isFullPageLayout ? (
      !this.state.isNewTopbar ? (
        <HeaderTopTwo
          image={this.state.profileImage}
          msgCountGlobal={this.state.msgCountGlobal}
          socketThread={this.state.socketThread}
          messagePageActive={this.state.messagePageActive}
          updatePropsGlobalmsg={this.updatePropsGlobalmsg}
          setpsCount={this.state.setpsCount}
        />
      ) : (
        <HeaderTop
          {...this.props}
          imgFile={this.state.imgUrl}
          image={this.state.profileImage}
          property_name={this.state.property_name}
          property_slug={this.state.property_slug}
          msgCountGlobal={this.state.msgCountGlobal}
          socketThread={this.state.socketThread}
          messagePageActive={this.state.messagePageActive}
          updatePropsGlobalmsg={this.updatePropsGlobalmsg}
          setpsCount={this.state.setpsCount}
        />
      )
    ) : (
      ""
    );

    return (
      <div className="d-flex flex-column">
        {HeaderTopComponent}
        <div className="d-flex">
          {/* for main menu */}
          <div
            className="az-sidebar"
            onClick={(event) => this.toggleSecondaryMainMenu(event)}
          >
            {headerComponent}
          </div>
          <div
            className="right-sidebar"
            onClick={(event) => this.toggleSecondaryMenu(event)}
          >
            <div className="az-content-wrapper">
              {/* <AppRoutes updateProfileImage={this.updateProfile} updatePropertyLogo={this.updatePropertyLogo} /> */}
              {/* <div className="page">
                <button onClick={this.buttonClick} className="button">
                  Hello world.
                </button>

              </div> */}
              <AppRoutes
                updateProfileImage={this.updateProfile}
                socketData={this.state.socketData}
                updatePropertyLogo={this.updatePropertyLogo}
                propertyName={this.getPropertyName}
                propertySlug={this.getPropertySlug}
                propertyLogo={this.getPropertyLogo}
                propertySKU={this.getPropertySKU}
                msgCountGlobal={this.state.msgCountGlobal}
                updatePropsGlobalmsg={this.updatePropsGlobalmsg}
                threadSlug={this.state.socketThread}
              />
            </div>
            {/* {footerComponent} */}
          </div>

          <div
            className="az-navbar-backdrop"
            onClick={(event) => this.toggleHeaderMenu(event)}
          >
            <img src={mobmenu} alt="" />
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const brandRoute = [
      "/brands",
      "/dashboard",
      "/properties",
      "/master-setup",
      "/store",
      "/analytics",
      "/company",
    ];
    const fullPageLayoutRoutes = [
      "/signin",
      "/2fa-signin",
      "/logout",
      "/page-403",
      "/forgot-password",
      "/2fa-forgot-password",
      "/reset-password",
      "/account-activation",
      "/view-report",
      "/view-reports",
      "/clear-cache",
      "/landing",
      "/oh-index",
      "/generate-password",
      "/mobile-reservation-calendar",
    ];
    const calendarRoutes = [
      "/unified-calendar",
      "/maintenance-calendar",
      "/reservation-calendar",
      "/checkin-calender",
      "/checkout-calender",
      "/list-calendar",
      "/lease-avail-calendar",
      "/global-unified-calendar",
      "/global-list-calendar",
      "/global-reservation-calendar",
      "/global-lease-avail-calendar",
      "/global-maintenance-calendar",
      "/global-checkin-calender",
      "/global-checkout-calender",
      "/housekeeping-calender",
    ];
    if (calendarRoutes.includes(this.props.location.pathname)) {
      document.body.classList.add("custom-calendar-class");
    } else {
      document.body.classList.remove("custom-calendar-class");
    }
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname.includes(fullPageLayoutRoutes[i])) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector(".az-sidebar").classList.add("d-none");
        document.querySelector(".right-sidebar").classList.add("w-100");
        document
          .querySelector(".right-sidebar")
          .classList.add("custom-top-header");
        document
          .querySelector(".az-navbar-backdrop")
          .classList.add("custom-wrapper");
        break;
      } else if (this.props.location.pathname.includes(brandRoute[i])) {
        document
          .querySelector(".right-sidebar")
          .classList.remove("custom-top-header");
        document
          .querySelector(".az-navbar-backdrop")
          .classList.remove("custom-wrapper");
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector(".az-sidebar").classList.remove("d-none");
        document
          .querySelector(".az-navbar-backdrop")
          .classList.remove("custom-wrapper");
        document.querySelector(".right-sidebar").classList.remove("w-100");
      }
    }
    const newSidebarRoutes = ["/properties/view"];
    for (let i = 0; i < newSidebarRoutes.length; i++) {
      if (this.props.location.pathname.includes(newSidebarRoutes[i])) {
        this.setState({
          isNewSidebar: true,
        });
      } else {
        this.setState({
          isNewSidebar: false,
        });
      }
    }
    const newTopbarRoutes = ["/properties/view"];
    for (let i = 0; i < newTopbarRoutes.length; i++) {
      if (this.props.location.pathname.includes(newTopbarRoutes[i])) {
        this.setState({
          isNewTopbar: true,
        });
      } else {
        this.setState({
          isNewTopbar: false,
        });
      }
    }
  }
}

export default withRouter(App);
